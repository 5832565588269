import React from 'react'
import { RightArrowGreen } from 'src/components/svgs'

export interface HeaderLinksProps {
  title: string
  Items: any
}

export const HeaderLinks = ({ title, Items }: HeaderLinksProps) => {
  return (
    <div className={`header-bulletin header-links border-top-grey `}>
      <div className="__header padding-box">
        <h1 className="neue-pro-light-45-large-nums">{title}</h1>
      </div>
      <div className="padding-box span-2">
        {Items.map(({ title, linkText, file }, index) => (
          <div key={index} className="header-links__links-container margin-bottom-em2">
            <div>
              <p className="blue neue-pro-light-45-bullet margin-top-059rem">{title}</p>
            </div>
            <div className="flex">
              <RightArrowGreen className="--right-arrow" />
              <a
                className="neue-pro-55-text-links green align-items-center green-hover-on-text"
                target="_blank"
                rel="noreferrer"
                href={`${file.asset.url}?dl=${file.asset.originalFilename}`}
              >
                {linkText}
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
