import React from 'react'
import { StandardText } from 'src/components/global/standardText'
import { RightArrowGreen } from 'src/components/svgs'
import BlockContent from '@sanity/block-content-to-react'
import richText from 'src/serializers/richText'

export interface TextWithLinksProps {
  description: any
  links: any
}

const TextWithLinks = ({ description = [], links = [] }: TextWithLinksProps) => {
  return (
    <div className="text-with-links padding-box border-top-grey">
      <div>
        <div className="rich-text margin-bottom-em3">
          <BlockContent blocks={description} serializers={richText} />
        </div>
      </div>
      <div>
        {links.map(({ link }, index) => (
          <div key={index} className="margin-bottom-em2">
            <div className="flex">
              <RightArrowGreen className="--right-arrow" />
              <a
                className="neue-pro-55-text-links green align-items-center green-hover-on-text"
                target="_blank"
                rel="noreferrer"
                href={link.url}
              >
                {link.title}
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TextWithLinks
