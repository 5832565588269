import React, { useEffect, useState } from 'react'
import { PageLink } from 'src/components/link'

import { LogoGreen } from 'src/components/svgs'

import { RenderMediaTypeModules } from './mediaModules/renderModules'
import { PlaceHolderInsight } from '../placeholders/placeHolderInsight'
import Select from 'react-select'

import { NewspaperGreen, PencilGreen, MicrophoneGreen } from 'src/components/svgs'
import { MenuItem } from '../mobileBurgerMenu/menuItem'

const DropwDownMenuItems = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Articles',
    value: 'article',
  },
  {
    label: 'Press Releases',
    value: 'external',
  },
]

export interface FilterMediaTypeContainerProps {
  data: any
}

export const FilterMediaTypeContainer = ({ data }: FilterMediaTypeContainerProps) => {
  const [media, setMedia] = useState([])
  const [isLoading, setIsloading] = useState(false)
  const [isError, setIsError] = useState(false)
  const { references } = data

  return (
    <div className="media-filter-section">
      {references &&
        references.map((item, index) => {
          switch (item._type) {
            case 'insightBlockFeatured':
              return (
                <div>
                  <FeaturedInsightBlock {...item} />
                </div>
              )
            case 'insightBlocks' || 'externalArticles':
              return <InsightBlocks {...item} index={index} />
            default:
              return null
          }
        })}
    </div>
  )
}

const InsightBlocks = ({ externalArticles, _key }: any) => {
  return (
    <div key={_key} className="insight-blocks">
      {externalArticles &&
        externalArticles.map((item, index) => {
          const { _type, _key } = item
          switch (_type) {
            case 'externalArticle':
              // eslint-disable-next-line no-var
              var { id, title, shortDescription, url, typeOfMedia } = item
              return (
                <a
                  className={` __block padding-box neue-pro-55-text-links green ${
                    index === 5 ? '' : ''
                  }`}
                  target="_blank"
                  rel="noopener _custom noreferrer"
                  href={url}
                  style={{ textDecoration: 'none' }}
                  key={_key}
                >
                  <div className="__title">
                    <p className="margin-bottom-2rem neue-pro-55-text-links blue ">{title}</p>
                    <p className="  related-content-links-font-45-light blue ">
                      {shortDescription}
                    </p>
                  </div>
                  <div className="__link-container">
                    <div className="__row-link">
                      <RenderSvgType
                        type={
                          typeOfMedia && typeOfMedia.slug.current
                          ? typeOfMedia.slug.current
                          : "external"
                        }
                      />
                      <a
                        className=" neue-pro-55-text-links green text-decoration-none green-hover-on-text  "
                        target="_blank"
                        rel="noopener _custom noreferrer"
                        href={url}
                      >
                        Read More
                      </a>
                    </div>
                  </div>
                </a>
              )
            case 'post':
              return (
                <PageLink
                  to={`/article/${item.content.main.slug.current}`}
                  className={` __block padding-box neue-pro-55-text-links green ${
                    index === 5 ? '' : ''
                  }`}
                  style={{ textDecoration: 'none' }}
                  key={item.id}
                >
                  <div className="__title">
                    <p className="margin-bottom-2rem neue-pro-55-text-links blue ">
                      {item.content.main.title}
                    </p>
                    <p className="  related-content-links-font-45-light blue ">
                      {item.content.main.shortDescription}
                    </p>
                  </div>
                  <div className="__link-container">
                    <div className="__row-link">
                      <RenderSvgType
                        type={
                          item.content.main.typeOfMedia &&
                          item.content.main.typeOfMedia.slug.current
                        }
                      />

                      {/* <NewspaperGreen className="__svg" /> */}
                      <span className=" neue-pro-55-text-links green text-decoration-none green-hover-on-text  ">
                        Read More
                      </span>
                    </div>
                  </div>
                </PageLink>
              )
            default:
              return null
          }
        })}

      <div className=" __block neue-pro-55-text-links green"></div>
    </div>
  )
}

const RenderSvgType = ({ type }: any) => {
  switch (type) {
    case 'article':
      return <PencilGreen className="__svg" />
    case 'external':
      return <NewspaperGreen className="__svg" />
    case 'podcast':
      return <MicrophoneGreen className="__svg" />
    default:
      return null
  }
}

const FeaturedInsightBlock = ({
  featuredBlockImage,
  featuredExternalArticleReference,
  svg,
  _key,
}: any) => {
  const { _type } = featuredExternalArticleReference
  switch (_type) {
    case 'externalArticle':
      return (
        <a
          style={{ textDecoration: 'none' }}
          key={_key}
          rel="noopener _custom noreferrer"
          href={featuredExternalArticleReference.url}
          className="__featured-block-article"
        >
          <div className="__featured-block-text padding-box">
            <div className="__general">
              {/* The SVG prop seems to be hidden now. Keeping in case we need to restore. We can delete if this isn't needed down the road. */}
              {/* <div
                className="margin-bottom-2rem"
                dangerouslySetInnerHTML={{ __html: svg }}
              /> */}
              <p className="margin-bottom-2rem neue-pro-55-text-links blue ">
                {featuredExternalArticleReference.title}
              </p>
              <p className=" related-content-links-font-45-light blue ">
                {featuredExternalArticleReference.shortDescription}
              </p>
            </div>
            <div className="__link-container">
              <div className="flex __row">
                <RenderSvgType type="external" />

                <a
                  className=" neue-pro-55-text-links green  text-decoration-none green-hover-on-text "
                  target="_blank"
                  rel="noopener _custom noreferrer"
                  href={featuredExternalArticleReference.url}
                >
                  Read More
                </a>
              </div>
            </div>
            <div></div>
          </div>
          <div
            className=" __featured-block-background-image y x _max-height-image border-bottom-grey "
            style={{
              backgroundImage: `url(${featuredBlockImage.asset.url})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          />
        </a>
      )
    case 'post':
      return (
        <PageLink
          style={{ textDecoration: 'none' }}
          key={_key}
          to={`/article/${featuredExternalArticleReference.content.main.slug.current}`}
          className="__featured-block-article"
        >
          <div className="__featured-block-text padding-box">
            <div className="__general">
              <p className="margin-bottom-2rem neue-pro-55-text-links blue ">
                {featuredExternalArticleReference.content.main.title}
              </p>
              <p className=" related-content-links-font-45-light blue ">
                {featuredExternalArticleReference.content.main.shortDescription}
              </p>
            </div>
            <div className="__link-container">
              <div className="flex __row">
                <RenderSvgType
                  type={featuredExternalArticleReference.content.main.typeOfMedia.slug.current}
                />
                <PageLink
                  className=" neue-pro-55-text-links green  text-decoration-none green-hover-on-text "
                  to={`/article/${featuredExternalArticleReference.content.main.slug.current}`}
                >
                  Read More
                </PageLink>
              </div>
            </div>
          </div>
          <div
            className="__featured-block-background-image y x _max-height-image border-bottom-grey"
            style={{
              backgroundImage: `url(${featuredBlockImage.asset.url})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          />
        </PageLink>
      )
  }
}
