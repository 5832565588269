import React from 'react'
import { Image } from 'src/components/image'

interface TextVisualBlockDoubleImageProps {
  data: {
    imageDoubleContainer: any[]
  }
}

export const TextVisualBlockDoubleImage = ({ data }: TextVisualBlockDoubleImageProps) => {
  const { imageDoubleContainer } = data
  return (
    <div className="image-double-container">
      {imageDoubleContainer &&
        imageDoubleContainer.map(item => {
          return (
            <div
              className="border-left-white border-right-white __image-container-block rels"
              key={item._key}
            >
              <Image className=" mh-500px y x " imageId={item.image.asset.id} />
              <div className="__image-text-overlay">
                <p className=" white neue-pro-light-45-headlines text-uppercase">{item.title}</p>
              </div>
            </div>
          )
        })}
    </div>
  )
}
