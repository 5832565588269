import React from 'react'
import { StandardText } from 'src/components/global/standardText'
import { PageLink } from 'src/components/link'
import { Image } from 'src/components/image'

import { MapSlide } from 'src/components/modules/mapBlocks/mapSlide'

import { RightArrowGreen } from 'src/components/svgs'

export interface TextVisualBlockMapSliderProps {
  data: any
}

export function TextVisualBlockMapSlider({ data }: TextVisualBlockMapSliderProps) {
  const { blockContent, image, text, title, position, linkToPage } =
    data.textVisualBlockImageDescriptionText
  const { offices } = data
  return (
    <div
      key={`3233`}
      className="visual-block-image-description-text  text-box-map-slider border-top-grey"
    >
      <div className="padding-box __info">
        <p className="neue-pro-55-text-links green text-center margin-bottom-em3">{title}</p>
        <StandardText
          className="neue-pro-light-45 text-center blue"
          data={{ text: blockContent }}
        />
      </div>
      <div style={{ gridRow: position ? '' : 1 }} className="__image-container padding-box">
        {image && (
          <Image
            className="margin-bottom-em3"
            objectFit="cover"
            height={1100}
            src={image.asset.url}
            imageId={image.asset.id}
          />
        )}
        {text && <p className=" margin-top-em3 neue-pro-55-text-links blue">{text}</p>}

        {linkToPage && (
          <div className="flex  margin-top-em3">
            <RightArrowGreen className="--right-arrow" />
            <PageLink
              activeClass="null"
              className="neue-pro-55-text-links green align-items-center green-hover-on-text"
              to={`/${linkToPage && linkToPage.content.main.slug.current}`}
            >
              {linkToPage.content.main.title}
            </PageLink>
          </div>
        )}
      </div>
      <MapSlide data={offices} />
    </div>
  )
}
