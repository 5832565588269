import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Select from 'react-select'
import { PageLink } from 'src/components/link'
import { StandardText } from 'src/components/global/standardText'
import { Image } from 'src/components/image'

import {
  useSetSelectedTeamLocationData,
  useTeamSelectedLocationData,
  useTeamFilterParametersData,
  useSetTeamFilterParametersData,
  useTeamSelectedRoleData,
  useTeamSetSelectedRoleData,
  useSetSelectedTeamProductData,
  useTeamSelectedTeamProductData,
} from 'src/context/scrollProvider'

export interface TeamListContainerProps {
  data: {
    teamMemberGroupList: Array<{
      _key: string
      title: string
      slug: {
        current: string
      }
      position: string
      hideFromTeamPage: any
      image: {
        asset: {
          id: string
          url: string
        }
      }
      teamMembersRoleRef: {
        title: string
        slug: {
          current: string
        }
      }

      teamMembersLocation: {
        title: string
        slug: {
          current: string
        }
      }
      description: string
    }>
  }
}

const ALL_CONST = {
  label: 'ALL',
  value: 'all',
}

export const TeamListContainer = ({ data }: TeamListContainerProps) => {
  const filterParameters = useTeamFilterParametersData()
  const setFilterParameters = useSetTeamFilterParametersData()
  const selectedProduct = useTeamSelectedTeamProductData()
  const setSelectedProduct = useSetSelectedTeamProductData()
  const selectedLocation = useTeamSelectedLocationData()
  const setSelectedLocation = useSetSelectedTeamLocationData()
  const selectedRole = useTeamSelectedRoleData()
  const setSelectedRole = useTeamSetSelectedRoleData()
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const teams = useStaticQuery(graphql`
    query HeaderQuery {
      allSanityTeamMember {
        edges {
          node {
            id
            title
            firstName
            lastName
            description
            slug {
              current
            }
            hideFromTeamPage
            productReferences {
              title
              slug {
                current
              }
            }
            image {
              asset {
                id
                url
                assetId
              }
            }
            _rawPositionMarkDown(resolveReferences: { maxDepth: 10 })
            productRef {
              ... on SanityCategory {
                title
                slug {
                  current
                }
              }
            }
            teamMembersRoleRef {
              title
              ... on SanityRoleRef {
                slug {
                  current
                }
              }
            }
            teamMembersLocation {
              title
              slug {
                current
              }
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    getTeamMembers()
  }, [])

  const viewAll = () => {
    setSelectedProduct(ALL_CONST)
    setSelectedLocation(ALL_CONST)
    setSelectedRole(ALL_CONST)
  }
  const getTeamMembers = async () => {
    try {
      const response = await fetch(`/.netlify/functions/teamFilterParams`)
      let json
      switch (response.status) {
        case 200:
          json = await response.json()
          setParameters(json.response, teams)
          setIsLoading(false)
          setIsError(false)
          break
        default:
          setIsError(false)
          setParameters([])
          setIsLoading(false)
          return null
      }
    } catch (err) {
      throw Error('An Unkown error occured')
    }
  }

  const setParameters = async array => {
    try {
      setIsLoading(true)
      if (array && array) {
        setFilterParameters({
          location: scaffoldCurrentDropDownValues(array, 'teamMembersLocation'),
          roles: scaffoldCurrentDropDownValues(array, 'teamMembersRoleRef'),
        })
        setIsLoading(false)
      }
    } catch (err) {
      setIsLoading(false)
      setIsError(true)
    }
  }

  if (isError) return 'Unkown Error Occureed'

  function filterArray(array, filters) {
    const filterKeys = Object.keys(filters)
    try {
      return array.filter(item => {
        return filterKeys.every(key => {
          if (typeof filters[key] !== 'function') return true

          return filters[key](item.node[key])
        })
      })
    } catch (err) {
      return []
    }
  }

  return (
    <div className="team-list-container-section rel rels border-top-grey ">
      <div className="flex filter-section  fade-in">
        <DropwDownSelect
          value={selectedRole}
          title="Role"
          onFunction={e => setSelectedRole(e)}
          filterParams={
            isLoading
              ? null
              : filterParameters && filterParameters.roles.filter(x => x.value !== 'advisors')
          }
        />
        <DropwDownSelect
          value={selectedLocation}
          title="Location"
          onFunction={e => setSelectedLocation(e)}
          filterParams={isLoading ? null : filterParameters.location}
        />

        {selectedProduct &&
        selectedProduct.value === 'all' &&
        selectedLocation &&
        selectedLocation.value === 'all' &&
        selectedRole &&
        selectedRole.value === 'all' ? null : (
          <button
            onClick={() => viewAll()}
            className="button fade-in font-65-medium  blue  align-self-flex-end"
          >
            View All
          </button>
        )}
      </div>

      <div className="__grid  --team-5">
        {filterArray(teams.allSanityTeamMember && teams.allSanityTeamMember.edges, {
          teamMembersLocation: (location: any) =>
            (location && location.slug.current === selectedLocation.value) ||
            selectedLocation.value === 'all',
          teamMembersRoleRef: (role: any) =>
            (role && role.slug.current === selectedRole.value) || selectedRole.value === 'all',
          hideFromTeamPage: (hidden: any) => hidden !== true,
          productReferences: (products: any) =>
            products
              .map(item => {
                return item.slug.current
              })
              .includes(selectedProduct.value.toLocaleLowerCase()) ||
            selectedProduct.value === 'all',
        })
          .filter(x => x.node.teamMembersRoleRef.slug.current !== 'advisors')
          .sort((a, b) => a.node.lastName.localeCompare(b.node.lastName))
          .map((item, index, array) => {
            return (
              <PageLink
                activeClass="null"
                key={item.key}
                className="text-decoration-none __team-member  fade-in"
                to={`/team/${item.node.slug.current}`}
                previousLink={`/our-team`}
              >
                <div className="image size--5x4">
                  <picture>
                    <img
                      alt={item.node.image.asset.alt}
                      src={`${item.node.image.asset.url}?w=400&auto=format&q=85`}
                    />
                  </picture>
                </div>

                <div className=" __grid2X2 flex column">
                  <div className=" flex column  --info ">
                    {item.node.firstName && item.node.lastName && (
                      <p className="blue neue-pro-55-text margin-bottom-2rem">{`${item.node.firstName} ${item.node.lastName}`}</p>
                    )}

                    {item.node._rawPositionMarkDown && (
                      <StandardText
                        className="blue neue-pro-55-text blue margin-bottom-2rem "
                        data={{ text: item.node._rawPositionMarkDown }}
                      />
                    )}
                    <PageLink
                      activeClass="null"
                      className="neue-pro-55-text green green-underline "
                      to={`/team/${item.node.slug.current}`}
                    >
                      {' '}
                      Read Bio
                    </PageLink>
                  </div>
                </div>
              </PageLink>
            )
          })}
      </div>
      {selectedRole &&
        selectedRole.value === 'all' &&
        selectedProduct &&
        selectedProduct.value === 'all' &&
        selectedLocation &&
        selectedLocation.value === 'all' && (
          <div className="__adivsor-team-section border-top-white">
            <h1 className="padding-box neue-pro-light-45-headlines blue text-center">
              Advisors & Operating Partners
            </h1>
            <div className="__grid  --team-5">
              {teams.allSanityTeamMember &&
                teams.allSanityTeamMember.edges
                  .filter(x => x.node.teamMembersRoleRef.slug.current === 'advisors')
                  .sort((a, b) => a.node.lastName.localeCompare(b.node.lastName))
                  .map(item => {
                    return (
                      <PageLink
                        activeClass="null"
                        key={item.key}
                        className="text-decoration-none __team-member  fade-in"
                        to={`/team/${item.node.slug.current}`}
                      >

                        <div className="image size--5x4">
                          <picture>
                            <img
                              alt={item.node.image.asset.alt}
                              src={`${item.node.image.asset.url}?w=400&auto=format&q=85`}
                            />
                          </picture>
                        </div>

                        <div className=" __grid2X2 flex column">
                          <div className=" flex column  --info ">
                            {item.node.firstName && item.node.lastName && (
                              <p className="blue neue-pro-55-text margin-bottom-2rem">{`${item.node.firstName} ${item.node.lastName}`}</p>
                            )}

                            {item.node._rawPositionMarkDown && (
                              <StandardText
                                className="blue neue-pro-55-text blue margin-bottom-2rem "
                                data={{ text: item.node._rawPositionMarkDown }}
                              />
                            )}

                            <PageLink
                              activeClass="null"
                              className="neue-pro-55-text green "
                              to={`/team/${item.node.slug.current}`}
                              previousLink={`/our-team`}
                            >
                              {' '}
                              Read Bio
                            </PageLink>
                          </div>
                        </div>
                      </PageLink>
                    )
                  })}
            </div>
          </div>
        )}
    </div>
  )
}

const customStyles = {
  container: () => ({
    position: 'relative',
    boxSizing: 'border-box',
    width: '12rem',
    zIndex: 9000,
  }),

  menu: () => ({
    position: 'absolute',
    margin: '0',
    width: '100%',
  }),
  menuList: () => ({
    padding: 0,
  }),
  option: (provided, state) => ({
    ...provided,

    color: state.isSelected ? '#0b1e31' : 'white',
    backgroundColor: state.isSelected ? 'white' : '#0b1e31',
    borderBottom: '1px solid white',
    padding: '0.5rem',
    fontFamily: 'Neue-Haas-Grotesk-Display-Pro-65-Medium',
    fontSize: '0.8rem',
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
  }),

  control: () => ({
    alignItems: 'center',
    backgroundColor: 'white',
    // borderRadius: "1px",
    borderWidth: '1px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    position: 'relative',
    borderColor: '#0b1e31',
    color: '#0b1e31',
    cursor: 'pointer',
    border: '2px solid #0b1e31',
    fontFamily: 'Neue-Haas-Grotesk-Display-Pro-65-Medium',
    fontSize: '0.08rem',
    letterSpacing: '0.1rem',
    // lineHeight: '1rem'
  }),

  placeholder: (provided, state) => ({
    fontFamily: 'Neue-Haas-Grotesk-Display-Pro-65-Medium',
    fontSize: '0.08rem',
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',

    color: '#0b1e31',
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = 'opacity 300ms'

    return {
      ...provided,
      opacity,
      transition,
      color: '#0b1e31',
      cursor: 'pointer',
      marginRight: '0.5rem',
      fontSize: '0.8rem',
      maxWidth: 'calc(100% - 8px)',
      overflow: 'hidden',
      position: 'absolute',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      top: '50%',
      transform: 'translateY(-50%)',
      boxSizing: 'borderBox',

      textTransform: 'uppercase',

      fontFamily: 'Neue-Haas-Grotesk-Display-Pro-65-Medium',
      letterSpace: '0.1rem',
    }
  },

  dropdownIndicator: () => ({
    padding: 0,
  }),

  indicatorSeparator: (provided, state) => ({
    display: 'none',
  }),
}

const DropwDownSelect = ({
  onFunction,
  title,
  filterParams,
  value,
}: {
  title: string
  onFunction: Function
  filterParams: any
}) => {
  return (
    <div className="_option">
      <p className=" --label blue  neue-pro-55-text ">{`${title}`}</p>
      <Select
        value={value}
        placeholder={value}
        onChange={e => onFunction(e)}
        styles={customStyles}
        options={filterParams}
      />
    </div>
  )
}

const scaffoldCurrentDropDownValues = (array, property) => {
  const unfilteredArray = array
    .filter(x => doesTitleExist(x[property]))
    .map(item => {
      return {
        value: item[property].slug.current,
        label: item[property].title,
      }
    })

  const filteredArray = unfilteredArray.filter(
    (v, i, a) => a.findIndex(t => t.value === v.value) === i
  )
  filteredArray.unshift({ value: 'all', label: 'All' })
  return filteredArray
}

const doesTitleExist = emb => {
  try {
    return emb.title
  } catch (err) {
    // console.log(err)
    return false
  }
}
