import { DropPinGreen } from '../../svgs'

import React, { useState, useRef } from 'react'
import ReactMapGL, { Marker } from 'react-map-gl'
import { MapSlide } from './mapSlide'

const SvgController = ({ type }: any) => {
  switch (type) {
    case 'buyouts':
      return (
        <div>
          <DropPinGreen onClick={() => {}} className="buyout-svg-green" />
        </div>
      )
    case 'infrastructure':
      return (
        <div>
          <DropPinGreen onClick={() => {}} className="infra-svg-green" />
        </div>
      )
    default:
      return null
  }
}

export function MapContactLocation({ data }: any) {
  const mapRef = useRef()
  const { geoLocationsContactInformation } = data
  const [viewport, setViewport] = useState({
    latitude: 51.288,
    longitude: -31.94,
    width: '100%',
    height: '50rem',
    zoom: 2.34,
  })

  const mapToMapSlide = data.geoLocationsContactInformation.map(item => {
    return {
      location: {
        lat: item.geopoint.lat,
        lng: item.geopoint.lng,
        _key: item._key,
      },
      title: item.name,
      svgPicker: {
        title: item.name,
        _key: item._key,
      },
    }
  })
  return (
    <div style={{ minHeight: '40rem' }} className="general-contact-map">
      <div className="__contact-mobile-map">
        <MapSlide data={{ location: '', locations: mapToMapSlide }} />
      </div>

      <div className="__contact-desktop-map">
        <ReactMapGL
          {...viewport}
          mapStyle={process.env.GATSBY_MAPBOX_STYLE}
          scrollZoom={false}
          maxZoom={1.5}
          mapboxApiAccessToken={process.env.GATSBY_MAPBOX_API_TOKEN}
          ref={mapRef}
        >
          {geoLocationsContactInformation &&
            geoLocationsContactInformation.map(item => {
              return (
                <Marker
                  key={item._key}
                  offsetLeft={-20}
                  offsetTop={-35}
                  latitude={item.geopoint.lat}
                  longitude={item.geopoint.lng}
                >
                  <p className="green neue-pro-light-45-bullet __marker-info  __contrty-title ">
                    {item && item.name}
                  </p>
                  <SvgController
                    onClick={() => console.log('')}
                    type={item && item.focusRadioSelect}
                  />
                </Marker>
              )
            })}
        </ReactMapGL>
      </div>
    </div>
  )
}
