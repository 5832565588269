import React from 'react'
import { StandardText } from './standardText'

export interface ArticelStandardTextPros {
  data: any
}

export const ArticleStandardText = ({ data }: ArticelStandardTextPros) => {
  return (
    <div className="article-standard-text padding-box">
      <StandardText
        className="neue-pro-55-editorial-text __article-body ma"
        data={{ text: data.content }}
      />
    </div>
  )
}
