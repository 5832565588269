import React from 'react'
import { Image } from 'src/components/image'

export interface ImageFullWidthProps {
  data: any
}

export const ImageFullWidth = ({ data }: ImageFullWidthProps) => {
  const { image } = data
  return (
    <div className="image-full-section">
      <Image
        src={!image.asset.id ? image.asset.url : ''}
        style={{
          height: '100%',
        }}
        className="__image-section x y "
        objectFit="cover"
        imageId={image.asset.id}
      />
    </div>
  )
}
