import React from 'react'
import { StandardText } from 'src/components/global/standardText'

export interface TextBlockMiscData {
  data: {
    title: string
    miscDataArray: Array<{
      _key: string
      titleContent: any[]
      textGreenContent: any[]
      textContentBottom: any[]
    }>
  }
}

export const TextBlockMiscData = ({ data }: TextBlockMiscData) => {
  const { miscDataArray } = data
  return (
    <div className="Text-Block-MiscData ">
      {miscDataArray &&
        miscDataArray.map(item => {
          const { titleContent, textGreenContent, textContentBottom } = item
          return (
            <div key={item._key} className=" __misc-box padding-box ">
              {titleContent && (
                <StandardText
                  className="neue-pro-55-text-links  white margin-bottom-2em "
                  data={{ text: titleContent }}
                />
              )}
              {textGreenContent && (
                <StandardText
                  className="green  neue-pro-light-45"
                  data={{ text: textGreenContent }}
                />
              )}
              {textContentBottom && (
                <StandardText
                  className="neue-pro-55-text-links  white margin-top-2em"
                  data={{ text: textContentBottom }}
                />
              )}
            </div>
          )
        })}
    </div>
  )
}
