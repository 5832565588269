import React from 'react'

export interface PodCastProps {
  data: any
}

export const PodCast = ({ data }: PodCastProps) => {
  const { podcastUrl } = data
  return (
    <div className="podcast-container">
      <iframe src={podcastUrl} width="100%" scrolling="no"></iframe>
    </div>
  )
}
