import React from 'react'
import { StandardText } from 'src/components/global/standardText'

import { BuyOutWhite, InfrastructureWhite, GrowthWhite } from 'src/components/svgs'

interface SvgControllerProps {
  type: any
}

interface SnapShotsProps {
  data: {
    snapshotsRef: Array<{
      funds: string
      description: string
      founded: string
      investedCapital: string
      investmentProfessionals: string
      portfolioAssets: string
      svgType: string
      title: string
      _key: string
    }>
  }
}

const SvgController = ({ type }: SvgControllerProps) => {
  switch (type) {
    case 'buyouts':
      return <BuyOutWhite className="--buyout-svg" />
    case 'infrastructure':
      return <InfrastructureWhite className="--infrastructure-svg" />
    case 'growth':
      return <GrowthWhite className="--infrastructure-svg" />
    default:
      return null
  }
}

const TitleBlock = ({ title, content, key }: any) => {
  return (
    <div key={key} className="title-block">
      <h1 className="neue-pro-55-text-links  ">{title}</h1>
      <p className="neue-pro-light-45-large-nums">{content}</p>
    </div>
  )
}

export const SnapShots = ({ data }: SnapShotsProps) => {
  const { snapshotsRef } = data
  return (
    <div className="snapshots">
      {snapshotsRef &&
        snapshotsRef.map(item => {
          return (
            <div key={item._key} className="snapshot padding-box __grid  ">
              <div className="flex justify-center">
                <SvgController type={item.svgType} />
              </div>
              <div className="__info">
                <p className="white optima-standardtext __description">{item.description}</p>
                <div className="__stat-grid">
                  {item &&
                    item.statRepeater.map(({ _key, title, description }: any) => {
                      return <TitleBlock key={_key} title={title} content={description} />
                    })}
                </div>
                <StandardText
                  className="neue-pro-55-text-links green margin-top-2rem"
                  data={{ text: item.content }}
                />
              </div>
            </div>
          )
        })}
    </div>
  )
}
