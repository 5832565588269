import React, { useState, useEffect } from 'react'
import { StandardText } from '../global/standardText'
import { PageLink } from 'src/components/link'
import { useStaticQuery, graphql } from 'gatsby'
import Select from 'react-select'

import {
  useBuyoutsFundSelect,
  useBuyoutsSelectedStatus,
  useBuyoutsSetSelectedFund,
  useBuyoutsSetSelectedStatus,
} from 'src/context/scrollProvider'

export interface FilterContainerPortfolioProps {
  data: any
}

export const FilterContainerPortfolio = ({ data }: FilterContainerPortfolioProps) => {
  const setSelectedFund = useBuyoutsSetSelectedFund()
  const selectedFund = useBuyoutsFundSelect()
  const selectedStatus = useBuyoutsSelectedStatus()
  const setSelectedStatus = useBuyoutsSetSelectedStatus()
  const { allSanityCaseStudy, allSanityFund, allSanityInvestmentStatusCategory } =
    useStaticQuery(graphql`
      query caseStudies {
        allSanityCaseStudy: allSanityCaseStudy {
          edges {
            node {
              _rawContent(resolveReferences: { maxDepth: 9 })
            }
          }
        }
        allSanityFund(filter: { slug: { current: { nin: ["1", "2", "3"] } } }) {
          edges {
            node {
              title
              id
              slug {
                current
              }
            }
          }
        }
        allSanityInvestmentStatusCategory: allSanityInvestmentStatusCategory {
          edges {
            node {
              id
              title
              slug {
                current
              }
            }
          }
        }
      }
    `)

  const viewAll = () => {
    setSelectedFund({
      label: 'ALL',
      value: 'all',
    })
    setSelectedStatus({
      label: 'ALL',
      value: 'all',
    })
  }

  const filterArray = (array, filters) => {
    const filterKeys = Object.keys(filters)
    return array.filter(item => {
      // validates all filter criteria
      return filterKeys.every(key => {
        // ignores non-function predicates
        if (typeof filters[key] !== 'function') return true
        return filters[key](item.node._rawContent.main.modules[key])
      })
    })
  }

  if (!selectedStatus && !selectedStatus) return null

  return (
    <div className="team-list-container-section rel rels ">
      <div className="border-top-grey">
        <div
          style={{ alignItems: 'flex-end' }}
          className="flex filter-section   __filters-portfolio"
        >
          <DropwDownSelect
            value={selectedStatus}
            title="Investment Status"
            onFunction={e => setSelectedStatus(e)}
            filterParams={scaffoldCurrentDropDownValues(allSanityInvestmentStatusCategory.edges)}
          />
          <DropwDownSelect
            value={selectedFund}
            title="Fund"
            onFunction={e => setSelectedFund(e)}
            filterParams={scaffoldCurrentDropDownValues(allSanityFund.edges)}
          />

          {selectedStatus.value === 'all' && selectedFund.value === 'all' ? null : (
            <button
              onClick={() => viewAll()}
              className="button fade-in font-65-medium  blue  align-self-flex-end"
            >
              View All
            </button>
          )}
        </div>
      </div>

      <div className="__grid">
        {filterArray(allSanityCaseStudy && allSanityCaseStudy.edges, {
          funds: (fund: any) =>
            (fund && fund.slug.current === selectedFund.value) || selectedFund.value === 'all',
          statusCategory: (stat: any) =>
            (stat && stat.slug.current === selectedStatus.value) || selectedStatus.value === 'all',
        })
          .filter(
            x => x && x.node._rawContent.main.modules.portfolioCategory?.slug.current === 'buyouts'
          )
          .sort(function (a, b) {
            const yearA = new Date(a.node._rawContent.main.investmentDate)
            const yearB = new Date(b.node._rawContent.main.investmentDate)
            return yearB - yearA
          })
          .map((item, index) => {
            return <InsightBlockLink data={item} key={index} url="/" />
          })}
      </div>
    </div>
  )
}

const customStyles = {
  container: () => ({
    position: 'relative',
    boxSizing: 'border-box',
    width: '12rem',
    zIndex: 9000,
  }),

  menu: () => ({
    position: 'absolute',
    margin: '0',
    width: '100%',
  }),
  menuList: () => ({
    padding: 0,
  }),
  option: (provided, state) => ({
    ...provided,

    color: state.isSelected ? '#0b1e31' : 'white',
    backgroundColor: state.isSelected ? 'white' : '#0b1e31',
    borderBottom: '1px solid white',
    padding: '0.5rem',
    fontFamily: 'Neue-Haas-Grotesk-Display-Pro-65-Medium',
    fontSize: '0.8rem',
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
  }),

  control: () => ({
    alignItems: 'center',
    backgroundColor: 'white',
    borderWidth: '1px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    position: 'relative',
    borderColor: '#0b1e31',
    color: '#0b1e31',
    cursor: 'pointer',
    border: '2px solid #0b1e31',
    fontFamily: 'Neue-Haas-Grotesk-Display-Pro-65-Medium',
    fontSize: '0.08rem',
    letterSpacing: '0.1rem',
    // lineHeight: '1rem'
  }),

  placeholder: (provided, state) => ({
    fontFamily: 'Neue-Haas-Grotesk-Display-Pro-65-Medium',
    fontSize: '0.08rem',
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',

    color: '#0b1e31',
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = 'opacity 300ms'

    return {
      ...provided,
      opacity,
      transition,
      color: '#0b1e31',
      cursor: 'pointer',
      marginRight: '0.5rem',
      fontSize: '0.8rem',
      maxWidth: 'calc(100% - 8px)',
      overflow: 'hidden',
      position: 'absolute',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      top: '50%',
      transform: 'translateY(-50%)',
      boxSizing: 'borderBox',
      textTransform: 'uppercase',
      fontFamily: 'Neue-Haas-Grotesk-Display-Pro-65-Medium',
      letterSpace: '0.1rem',
    }
  },

  dropdownIndicator: () => ({
    padding: 0,
  }),

  indicatorSeparator: (provided, state) => ({
    display: 'none',
  }),
}

const DropwDownSelect = ({
  onFunction,
  title,
  filterParams,
  value,
  width,
}: {
  title: string
  onFunction: Function
  filterParams: any
}) => {
  return (
    <div className=" _option">
      <p className=" --label blue  neue-pro-55-text ">{`${title}`}</p>
      <Select
        styles={customStyles}
        value={value}
        placeholder={value}
        onChange={e => onFunction(e)}
        options={filterParams}
      />
    </div>
  )
}

const InsightBlockLink = ({ data, url, key }: any) => {
  const [backgroundColor, setBackgroundColor] = useState('')
  const [contrastColor, setContrastColor] = useState('')
  const [isSvgLogoContrast, setIsSvgLogoContrast] = useState(false)
  const {
    slug,
    modules: { headerBlurb, sectorCategoryRef, companyColor, svgMarkDown, textContrastColors },
  } = data.node._rawContent.main
  const onMouseOverBackgroundColor = (companyColor: string) => {
    if (companyColor) {
      setContrastColor(textContrastColors && textContrastColors.value)
      setIsSvgLogoContrast(true)
      setBackgroundColor(companyColor)
    }
  }

  const onMouseOverLeave = () => {
    setBackgroundColor('white')
    setContrastColor('#38bd47')
    setIsSvgLogoContrast(false)
  }
  return (
    <PageLink
      activeClass="null"
      key={`filter-${key}`}
      to={`/insight/${slug.current}`}
      onMouseLeave={() => onMouseOverLeave('white')}
      onMouseOver={() => onMouseOverBackgroundColor(companyColor)}
      style={{
        background: backgroundColor === companyColor ? companyColor : 'white',
      }}
      className={`insight-block padding-box fade-in fade-out text-decoration-none flex column curser ${
        isSvgLogoContrast ? '--logo-selected' : ''
      } `}
    >
      <div className={`_insight-logo`} dangerouslySetInnerHTML={{ __html: svgMarkDown }} />
      <StandardText
        className={`optimo optima-subhead  margin-bottom-4rem margin-top-4rem ${
          isSvgLogoContrast ? 'white' : 'blue'
        }  `}
        data={{ text: headerBlurb }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          marginTop: 'auto',
        }}
      >
        <p
          style={{ color: isSvgLogoContrast ? 'white' : '#0b1e31 ' }}
          className="blue margin-top-4em margin-bottom-1em neue-pro-55-text-links"
        >
          {sectorCategoryRef.title}
        </p>
        <PageLink
          activeClass="null"
          style={{ color: isSvgLogoContrast ? 'white' : '#38bd47' }}
          className={`green neue-pro-55-text-links text-decoration-none ${
            isSvgLogoContrast ? 'white-text-underline ' : 'green-text-underline'
          }`}
          to={`/insight/${slug.current}`}
        >
          {' '}
          View Case Study
        </PageLink>
      </div>
    </PageLink>
  )
}

const scaffoldCurrentDropDownValues = (array: any) => {
  return array.map(item => {
    return {
      value: item.node.slug.current,
      label: item.node.title,
    }
  })
}
