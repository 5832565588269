import React, { useEffect, useState, useRef, useCallback } from 'react'
import cx from 'classnames'
import { StandardText } from 'src/components/global/standardText'
import MapGL, { Marker } from 'react-map-gl'
import { DropPinGreen, SvgController, RightArrowNavy, LeftArrowNavy } from 'src/components/svgs'
import { PageLink } from 'src/components/link'

import { navigate } from 'gatsby'

export interface LocationClustersByProductProps {
  data: any
}

export const LocationClustersByProduct = ({ data }: LocationClustersByProductProps) => {
  const { filterReference, mapLocationGeopoints, content, defaultCordinates } = data
  return (
    <div className="locations-clusters-product-section">
      <div className="__svg-container y ">
        <SvgController
          type={filterReference && filterReference.slug.current}
          className="__svg-button-cluster-header "
        />
        <div className="__label">
          <StandardText data={{ text: content }} className="green padding-box neue-pro-55-text " />
        </div>
      </div>
      <div className="__cluster-sizes">
        <DesktopMapBoxContainer
          defaultCordinates={defaultCordinates && defaultCordinates}
          mapLocationGeopoints={mapLocationGeopoints && mapLocationGeopoints}
        />
      </div>
    </div>
  )
}

function MobileMapSlider({ clusters, className }: any) {
  const [currentSlide, setCurrentSlide] = useState(0)
  const mapRef = useRef()
  const [viewport, setViewport] = useState({})
  const LoadMap = () => {
    const intialLocation = clusters[Object.keys(clusters)[currentSlide]]
    if (intialLocation && intialLocation[0].content.main.modules.geopoint) {
      const { lng, lat } = intialLocation[0].content.main.modules.geopoint
      setViewport({
        latitude: lat,
        longitude: lng,
        width: '100%',
        height: '100%',
        zoom: 3.5,
        scrollZoom: false,
        touchZoom: false,
        touchRotate: false,
      })
    }
  }

  useEffect(() => {
    function handleResize() {
      const intialLocation = clusters[Object.keys(clusters)[currentSlide]]
      if (intialLocation && intialLocation[0].content.main.modules.geopoint) {
        const { lng, lat } = intialLocation[0].content.main.modules.geopoint
        setViewport({
          latitude: lat,
          longitude: lng,
          width: '100%',
          height: '100%',
          zoom: 3.5,
        })
      }
    }
    window.addEventListener('resize', handleResize)
    handleResize()
  }, [])
  useEffect(() => {
    const intialLocation = clusters[Object.keys(clusters)[currentSlide]]
    if (intialLocation && intialLocation[0].content.main.modules.geopoint) {
      const { lng, lat } = intialLocation[0].content.main.modules.geopoint
      setViewport({
        latitude: lat,
        longitude: lng,
        width: '100%',
        height: '100%',
        zoom: 8.0,
        transitionDuration: 2000,
      })
    }
  }, [currentSlide])

  const onChangeNextLocation = () => {
    if (currentSlide <= Object.keys(clusters).length) {
      setCurrentSlide(currentSlide + 1)
    }
  }

  const onChangePreviousLocation = () => {
    if (currentSlide > Object.keys(clusters).length) {
      // noop
    } else {
      setCurrentSlide(currentSlide - 1)
    }
  }
  return (
    <div className="slider-location-container  __buyout-mobile-cluster">
      {currentSlide + 1 === Object.keys(clusters).length ? null : (
        <RightArrowNavy
          onClick={() => onChangeNextLocation()}
          className="__arrow --right fade-in"
        />
      )}
      {currentSlide === 0 ? null : (
        <LeftArrowNavy
          onClick={() => onChangePreviousLocation()}
          className="__arrow --left fade-in"
        />
      )}

      <MapGL
        {...viewport}
        mapStyle={process.env.GATSBY_MAPBOX_STYLE}
        scrollZoom={false}
        maxZoom={12}
        onLoad={() => LoadMap()}
        className="__slider"
        mapboxApiAccessToken={process.env.GATSBY_MAPBOX_API_TOKEN}
        onViewportChange={newViewport => {
          setViewport({ ...newViewport })
        }}
        ref={mapRef}
      ></MapGL>
    </div>
  )
}

const GroupLocationClusters = (array: any) => {
  const group = array.reduce((acc, item) => {
    if (!acc[item.content.main.modules.slug.current]) {
      acc[item.content.main.modules.slug.current] = []
    }

    acc[item.content.main.modules.slug.current].push(item)
    return acc
  }, {})

  return group
}

const DesktopMapBoxContainer = ({ clusters, mapLocationGeopoints, defaultCordinates }: any) => {
  const mapRef = useRef()
  const [viewport, setViewport] = useState({})
  const [openDropwDown, setOpenDropwDown] = useState('')
  const [hoverId, setHoverID] = useState('')
  const { defaultPosition, zoom } = defaultCordinates
  const handleViewportChange = useCallback(newViewport => setViewport(newViewport), [])

  const LocationPoint = ({ data }: any) => {
    const { locationPointReference, _key, insight } = data
    const { lat, lng } = locationPointReference.geopoint
    return (
      <div onMouseLeave={() => setHoverID('')}>
        <Marker
          longitude={lng}
          latitude={lat}
          className="__center"
          offsetLeft={-20}
          offsetTop={-30}
        >
          <DropPinGreen
            onMouseOver={() => setHoverID(_key)}
            onMouseLeave={() => null}
            onClick={() => navigate(`/insight/${insight.content.main.slug.current}`)}
            className="__svg-marker   cursor"
          />

          {hoverId === _key && (
            <div className="__dropdown-container fade-in">
              <PageLink
                activeClass="null"
                style={{ fontSize: '0.8rem' }}
                className="white neue-pro-55-text"
                key={`Dropdown-${insight.id}`}
                to={`/insight/${insight.content.main.slug.current}`}
              >
               {insight.content.main.mapTitleOverride ? insight.content.main.mapTitleOverride : insight.content.main.title}
              </PageLink>
            </div>
          )}
        </Marker>
      </div>
    )
  }
  const LocationCluster = ({ data }: any) => {
    const { title, _key, insightsDropDownCluster, locationClusterPointReference } = data

    const { geopoint } = locationClusterPointReference

    return (
      <div key={_key} onMouseOver={() => setHoverID(_key)} onMouseLeave={() => setHoverID('')}>
        <Marker
          longitude={geopoint.lng}
          latitude={geopoint.lat}
          className="__center"
          offsetLeft={-20}
          offsetTop={-30}
        >
          <DropPinGreen
            onMouseOver={() => setHoverID(_key)}
            onMouseLeave={() => console.log('')}
            onClick={() => null}
            className="__svg-marker   cursor"
          />
          {hoverId === _key && (
            <div className="__dropdown-container fade-in">
              {insightsDropDownCluster.map(item => {
                return (
                  <PageLink
                    style={{ fontSize: '0.8rem' }}
                    className="white neue-pro-55-text"
                    key={`Dropdown-${item.id}`}
                    to={`/insight/${item.content.main.slug.current}`}
                  >
                   {item.content.main.mapTitleOverride ? item.content.main.mapTitleOverride : item.content.main.title}
                  </PageLink>
                )
              })}
            </div>
          )}
        </Marker>
      </div>
    )
  }

  return (
    <div className="locations-clusters-product-section__map-box-desktop x y ">
      <MapGL
        className="border-left-grey y "
        ref={mapRef}
        mapStyle={process.env.GATSBY_MAPBOX_STYLE}
        {...viewport}
        onLoad={() =>
          setViewport({
            maxZoom: zoom && zoom,
            minZoom: zoom && zoom,
            width: '100%',
            height: '100%',
            scrollZoom: false,
            touchZoom: false,
            touchRotate: false,
            zoom: zoom && zoom,
            longitude: defaultPosition && defaultPosition.lng,
            latitude: defaultPosition && defaultPosition.lat,
          })
        }
        onViewportChange={handleViewportChange}
        mapboxApiAccessToken={process.env.GATSBY_MAPBOX_API_TOKEN}
      >
        <DirectionSvg className="__directions-svg" />
        {mapLocationGeopoints &&
          mapLocationGeopoints.map(item => {
            const { _type } = item
            switch (_type) {
              case 'locationPoint':
                return <LocationPoint data={item} />
              case 'clusterPoint':
                return <LocationCluster data={item} />
              default:
                return null
            }
          })}
      </MapGL>
    </div>
  )
}

const DirectionSvg = ({ className }) => {
  return (
    <svg
      className={cx('__directions-svg', className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52.31 52.31"
    >
      <g data-name="Layer 2">
        <path
          d="M31.32 27.18h4.44v2.08h-4.44zm0-2.08h4.44V23h-4.44zM21 23.05h-4.45v2.08H21zm0 4.16h-4.45v2.08H21zm4.13 2.08V16.55H23v19.21h2.1zm2.08 6.47h2.08V16.55h-2.11V23zM5.87 32L9 35.16l1.47-1.47-3.13-3.14zm2.94-2.94L12 32.22l1.47-1.47-3.14-3.14zm1.65-10.44L9 17.16l-9 9 1.47 1.47zm1.47 1.47l-9 9 1.47 1.47 9-9zm9.83-12.75l-1.47-1.47L17.15 9l1.47 1.47zm1.47 1.47L20.09 12l1.47 1.47 3.14-3.14zm10.44 1.65L35.15 9l-9-9-1.47 1.47zm-1.47 1.47l-9-9-1.46 1.48 9 9zm14.24 8.36l-3.14-3.14-1.47 1.47L45 21.76zm-2.94 2.94l-3.14-3.14-1.47 1.47L42 24.7zm7.34 1.45l-9 9 1.47 1.48 9-9zm-1.47-1.47l-1.47-1.47-9 9 1.47 1.47zM30.55 45L32 46.44l3.14-3.14-1.47-1.47zm1.67-4.61l-1.47-1.47L27.61 42l1.47 1.47zm-13.58 1.46l-1.48 1.47 9 9 1.47-1.47zm1.47-1.47l9 9 1.47-1.47-9-9z"
          className="cls-1"
          data-name="Layer 1"
        ></path>
      </g>
    </svg>
  )
}
