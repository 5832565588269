import React from 'react'
import { BuyOutWhite, InfrastructureWhite } from 'src/components/svgs'
import { StandardText } from 'src/components/global/standardText'
import imageUrlBuilder from '@sanity/image-url'

const sanityConfig = {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
}

const builder = imageUrlBuilder(sanityConfig)

function urlFor(source) {
  return builder.image(source)
}

export interface TextVisualBlockMultiTextProps {
  data: any
}

export const TextVisualBlockMultiText = ({ data }: TextVisualBlockMultiTextProps) => {
  const { multiTextTitle, content, smallTextContent, image, position, textOverLay, _ref } = data
  return (
    <div className="text-visual-block-multi-text">
      <div className="__info padding-box flex column justify-center">
        {multiTextTitle && (
          <p className="neue-pro-55-text-links green text-center margin-bottom-em3">
            {multiTextTitle}
          </p>
        )}
        {content && (
          <StandardText
            className="neue-pro-light-45 text-center blue inner-padding-wrapper "
            data={{ text: content }}
          />
        )}
        {smallTextContent && (
          <p className="  blue  text-center ma mw-33em  margin-top-em3 neue-pro-55-text-links">
            {smallTextContent}
          </p>
        )}
      </div>
      <div
        style={{
          gridRow: position && position ? 1 : '',
          backgroundImage: `url(${urlFor(image).url()})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }}
        className="image-container "
      >
        {textOverLay && (
          <div className="textOverLay">
            <SvgController title={textOverLay} />
          </div>
        )}
      </div>
    </div>
  )
}

const SvgController = ({ title }: any) => {
  const lowercase = title.toLowerCase()
  switch (lowercase) {
    case 'buyouts':
      return <BuyOutWhite className="--buyout-svg" />
    case 'infrastructure':
      return <InfrastructureWhite className="--infrastructure-svg" />
    default:
      return <p className=" white neue-pro-light-45-headlines text-uppercase">{title}</p>
  }
}
