import React from 'react'
import { Image } from 'src/components/image'
import { StandardText } from 'src/components/global/standardText'

export interface TextBlockDoubleLocationProps {
  data: any
}

export const TextBlockDoubleLocation = ({ data }: TextBlockDoubleLocationProps) => {
  const { locations } = data
  return (
    <div className="text-block-double-location-container">
      {locations &&
        locations.map(item => {
          return (
            <div key={item._key} className="__location-info">
              <div className="-descriptions padding-box">
                <h1 className="green optima-headline-pt2">{item.title}</h1>
                <StandardText
                  className="white neue-pro-light-45-alt-subheads margin-top-em2"
                  data={{ text: item.contactInformation }}
                />
              </div>
              <Image imageId={item && item.image.asset.id} />
            </div>
          )
        })}
    </div>
  )
}
