import React from 'react'

import { StandardText, StandardTextProps } from 'src/components/global/standardText'
import {
  ArticleStandardText,
  ArticelStandardTextPros,
} from 'src/components/global/articleStandardText'
import { NestedPages, NestedPagesProps } from 'src/components/global/nestedPages'
import {
  VisualBlockImageDescriptionText,
  VisualBlockImageDescriptionTextProps,
} from 'src/components/modules/textVisualBlock/textVisualBlockImageDescriptionText'
import {
  TextVisualBlockMultiText,
  TextVisualBlockMultiTextProps,
} from 'src/components/modules/textVisualBlock/textVisualBlockMultiText'
import {
  TextVisualBlockSingle,
  TextVisualBlockSingleProps,
} from 'src/components/modules/textVisualBlock/textVisualBlockSingle'
import {
  TextVisualBlockMapSlider,
  TextVisualBlockMapSliderProps,
} from 'src/components/modules/textVisualBlock/textVisualBlockMapSlider'
import { TextBlockContactGeneralInquiries } from 'src/components/modules/textBlocks/textBlockContactGeneralInquiries'
import {
  TextBlockDoubleLocation,
  TextBlockDoubleLocationProps,
} from 'src/components/modules/textBlocks/textBlockDoubleLocation'
import {
  TextBlockFocusBlurb,
  TextBlockFocusBlurbProps,
} from './modules/textBlocks/textBlockFocusBlurb'
import {
  TextBlockOneFullTextBlue,
  TextBlockFullTextBlueProps,
} from 'src/components/modules/textBlocks/textBlockFullTextBlue'
import {
  TextBlockMiscData,
  TextBlockMiscDataProps,
} from 'src/components/modules/textBlocks/textBlockMiscData'
import {
  TextBlockFullWhite,
  TextBlockFullWhiteProps,
} from 'src/components/modules/textBlocks/textBlockFullTextWhite'
import {
  TextBlockRelatedMediaBanner,
  TextBlockRelatedMediaBannerProps,
} from 'src/components/modules/textBlocks/textBlockRelatedMediaBanner'
import { TextVisualBlockDoubleImage } from 'src/components/modules/textVisualBlock/textVisualBlockDoubleImage'
import { TextDisclaimer } from 'src/components/modules/textBlocks/textDisclaimer'
import {
  MapContactLocation,
  MapContactLocationProps,
} from 'src/components/modules/mapBlocks/mapContactLocation'
import { SnapShots } from 'src/components/modules/snapshots/index'
//Article
import { PodCast, PodCastProps } from 'src/components/modules/postBlocks/podcast'

//Globals

import { HeaderBulletin, HeaderBulletinProps } from 'src/components/global/headerBulletin'
import { HeaderLinks } from 'src/components/global/headerLinks'
import TextWithLinks from 'src/components/TextWithLinks'
import { ImageFullWidth, ImageFullWidthProps } from 'src/components/global/imageFullWidth'
import { SimpleHeader, SimpleHeaderProps } from 'src/components/global/simpleHeader'

import {
  PortfolioInfrastructureDrawer,
  PortfolioInfrastructureDrawerProps,
} from 'src/components/modules/infrastructure/portfolioInfrastructureDrawer'

//Containers
import {
  TextTermsOfUse,
  TextTermsOfuseProps,
} from 'src/components/modules/textBlocks/textTermsOfUse'
import TeamMembersGrid from 'src/components/TeamMembersGrid'

import {
  FilterContainerPortfolio,
  FilterContainerPortfolioProps,
} from './containers/filterContainerPortfolio'

import {
  FilterMediaTypeContainer,
  FilterMediaTypeContainerProps,
} from './containers/filterTypeMediaContainer'

import { TeamListContainer, TeamListContainerProps } from './containers/teamListContainer'
import { LocationClustersByProduct } from './modules/mapBlocks/locationClustersByProduct'

export const Modules = ({
  reactModule,
  type,
  url,
}: {
  type: string
  reactModule: any
  url: string
}) => {
  switch (type) {
    case 'infrastructureDrawer':
      return (
        <PortfolioInfrastructureDrawer
          data={reactModule as PortfolioInfrastructureDrawerProps['data']}
        />
      )
    case 'textTerms':
      return <TextTermsOfUse data={reactModule as TextTermsOfuseProps['data']} />
    case 'standardText':
      return <StandardText data={reactModule as StandardTextProps['data']} />
    case 'nestedPages':
      return <NestedPages data={reactModule as NestedPagesProps['data']} />
    case 'textVisualBlockSingleText':
      return <TextVisualBlockSingle data={reactModule as TextVisualBlockSingleProps['data']} />
    case 'textVisualBlockMapSlider':
      return (
        <TextVisualBlockMapSlider data={reactModule as TextVisualBlockMapSliderProps['data']} />
      )
    case 'textVisualBlockImageDescriptionText':
      return (
        <VisualBlockImageDescriptionText
          data={reactModule as VisualBlockImageDescriptionTextProps['data']}
        />
      )
    case 'textVisualBlockMultiText':
      return (
        <TextVisualBlockMultiText data={reactModule as TextVisualBlockMultiTextProps['data']} />
      )
    case 'textBlockMiscData':
      return <TextBlockMiscData data={reactModule as TextBlockMiscDataProps['data']} />
    case 'textBlockOneFullTextWhite':
      return <TextBlockFullWhite data={reactModule as TextBlockFullWhiteProps['data']} />
    case 'textBlockTwoFullTextBlue':
      return <TextBlockOneFullTextBlue data={reactModule as TextBlockFullTextBlueProps['data']} />
    case 'textBlockDoubleLocation':
      return <TextBlockDoubleLocation data={reactModule as TextBlockDoubleLocationProps} />
    case 'simpleHeader':
      return <SimpleHeader data={reactModule as SimpleHeaderProps['data']} />
    case 'textBlockFocusBlurb':
      return <TextBlockFocusBlurb data={reactModule} />
    case 'imageFullWidth':
      return <ImageFullWidth data={reactModule as ImageFullWidthProps['data']} />
    case 'headerBulletin':
      return <HeaderBulletin data={reactModule as HeaderBulletinProps['data']} />
    case 'headerLinks':
      return <HeaderLinks {...reactModule} />
    case 'textWithLinks':
      return <TextWithLinks {...reactModule} />
    case 'snapShots':
      return <SnapShots data={reactModule} />
    case 'textBlockContactGeneralInquiries':
      return <TextBlockContactGeneralInquiries data={reactModule} />
    case 'textVisualBlockDoubleImage':
      return <TextVisualBlockDoubleImage data={reactModule} />
    case 'podcastUrl':
      return <PodCast data={reactModule as PodCastProps['data']} />
    case 'articleStandardText':
      return <ArticleStandardText data={reactModule as ArticelStandardTextPros['data']} />
    case 'relatedMediaBanner':
      return <TextBlockRelatedMediaBanner data={reactModule as TextBlockRelatedMediaBannerProps} />
    case 'contactInformationOffices':
      return <MapContactLocation data={reactModule as MapContactLocationProps} />
    case 'filterContainerInfrastructure':
      return (
        <div>
          <FilterContainerPortfolio
            url={url}
            data={reactModule as FilterContainerPortfolioProps['data']}
          />
        </div>
      )
    case 'mediaTypeContainer':
      return (
        <FilterMediaTypeContainer data={reactModule as FilterMediaTypeContainerProps['data']} />
      )
    case 'teamListContainer':
      return <TeamListContainer data={reactModule as TeamListContainerProps['data']} />
    case 'teamMembers':
      return <TeamMembersGrid {...reactModule} />

    case 'locationClusterByProduct':
      return (
        <LocationClustersByProduct data={reactModule as LocationClustersByProductProps['data']} />
      )
    case 'textDisclaimer':
      return (
        <div>
          <TextDisclaimer data={reactModule} />
        </div>
      )
    default:
      return <span>{type}</span>
  }
}
