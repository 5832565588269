import { DropPinGreen, RightArrowNavy, LeftArrowNavy } from 'src/components/svgs'
import 'mapbox-gl/dist/mapbox-gl.css'

import React, { useState, useRef, useEffect } from 'react'
import ReactMapGL, { Marker, FlyToInterpolator } from 'react-map-gl'

// added the following 6 lines.
import mapboxgl from 'mapbox-gl'

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

interface MapSlideProps {
  data: {
    location: string
    locations: Array<{
      _key: string
      title: string
      location: {
        lat: number
        lng: number
      }
    }>
  }
}

export function MapSlide({ data }: MapSlideProps) {
  const [currentSlide, setCurrentSlide] = useState(0)
  const { location, locations } = data

  const [viewport, setViewport] = useState({})
  const LoadMap = () => {
    const slide = currentSlide
    const intialLocation = locations[slide]
    const {
      location: { lat, lng },
    } = intialLocation
    setViewport({
      latitude: lat,
      longitude: lng,
      width: '100%',
      height: '100%',
      zoom: 3.5,
    })
  }
  useEffect(() => {
    function handleResize() {
      const intialLocation = locations[currentSlide]
      const {
        location: { lat, lng },
      } = intialLocation

      setViewport({
        latitude: lat,
        longitude: lng,
        height: '100%',
        width: '100%',
        zoom: 3.5,
      })
    }

    window.addEventListener('resize', handleResize)
    handleResize()
  }, [])
  useEffect(() => {
    const intialLocation = locations[currentSlide]
    const {
      location: { lat, lng },
    } = intialLocation
    if (lat && lng) {
      setViewport({
        latitude: lat,
        longitude: lng,
        width: '100%',
        height: '100%',
        zoom: 3.5,
      })
    }
  }, [currentSlide])

  const onChangeNextLocation = () => {
    if (currentSlide < locations.length - 1) {
      setCurrentSlide(currentSlide + 1)
    } else {
      setCurrentSlide(0)
    }
  }

  const onChangePreviousLocation = () => {
    if (currentSlide > locations.length - 1) {
      setCurrentSlide(currentSlide - 1)
    } else {
      const arr = locations.length
      if (arr - 1 === currentSlide) {
        setCurrentSlide(currentSlide - 1)
      } else {
        setCurrentSlide(arr - 1)
      }
    }
  }

  const mapRef = useRef()
  return (
    <div className="slider-location-container">
      {/* <p className="__title-of-location green neue-pro-55-text-links">Headquarters</p> */}
      <div className="__slider-controler">
        <div>
          {
            <LeftArrowNavy
              onClick={() => onChangePreviousLocation()}
              className="__arrow --left fade-out fade-in"
            />
          }
        </div>
        <div>
          {
            <RightArrowNavy
              onClick={() => onChangeNextLocation()}
              className="__arrow --right fade-out fade-in"
            />
          }
        </div>
      </div>
      <ReactMapGL
        {...viewport}
        mapStyle={process.env.GATSBY_MAPBOX_STYLE}
        scrollZoom={false}
        maxZoom={12}
        onLoad={() => LoadMap()}
        className="__slider"
        mapboxApiAccessToken={process.env.GATSBY_MAPBOX_API_TOKEN}
        onViewportChange={newViewport => {
          setViewport({ ...newViewport })
        }}
        ref={mapRef}
      >
        {locations &&
          locations.map(item => {
            return (
              <Marker
                key={item._key}
                offsetLeft={-55}
                offsetTop={-60}
                latitude={item.location.lat}
                longitude={item.location.lng}
              >
                <div className="__drop-pin-container">
                  <p className="green neue-pro-55-text-links __center-point">Headquarters</p>

                  <p className="green neue-pro-55-text-links __center-title">{item.title}</p>
                  <DropPinGreen onClick={() => {}} className="__pin" />
                </div>
              </Marker>
            )
          })}
      </ReactMapGL>
    </div>
  )
}
