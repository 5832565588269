import React, { useState } from 'react'
import { PageLink } from 'src/components/link'
import { StandardText } from 'src/components/global/standardText'
import { Plus, HamburgerLine } from 'src/components/svgs'
import { useSelectedDrawer, useSetSelectedDrawer } from 'src/context/scrollProvider'

export interface PortfolioInfrastructureDrawerProps {
  data: {
    _key: string
    svgLogo: string
    sectorOfCompany: string
    color: string
    title: string
    headerPhoto: {
      asset: {
        id: string
        url: string
      }
    }
    slug: string
    text: string
    website: string
    logo: {
      asset: {
        url: string
        id: string
      }
    }
    relatedCaseStudies: any
  }
}

export const PortfolioInfrastructureDrawer = ({ data }: PortfolioInfrastructureDrawerProps) => {
  const {
    logo,
    text,
    headerPhoto,
    color,
    website,
    relatedCaseStudies,
    _key,
    svgLogo,
    sectorOfCompany,
    title,
  } = data
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const selectedDrawer = useSelectedDrawer()
  const setSelectedDrawer = useSetSelectedDrawer()
  const toggleDrawer = () => {
    setSelectedDrawer(_key)
  }

  const closeDrawer = event => {
    event.stopPropagation()
    setSelectedDrawer(false)
  }
  return (
    <div id={`#${_key}`} className="infrastrucutre-drawer-container">
      <div className="__parent-company ">
        <ParentDrawerInsightBlockLink
          isDrawerOpen={selectedDrawer}
          toggleDrawer={() => toggleDrawer()}
          color={color}
          _key={_key}
          closeDrawer={event => closeDrawer(event)}
          svgLogo={svgLogo}
          text={text}
          title={title}
          sectorOfCompany={sectorOfCompany && sectorOfCompany.title}
          website={website}
        />

        <div
          className="y x _max-height-image border-top-grey "
          style={{
            backgroundImage: `url(${headerPhoto.asset.url})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        />
      </div>
      {_key == selectedDrawer ? (
        <div className="__open-container fade-in fade-out">
          {relatedCaseStudies &&
            relatedCaseStudies.map((item, index): any => {
              return <InsightBlockLink key={index} data={item} />
            })}
        </div>
      ) : null}
    </div>
  )
}

const InsightBlockLink = ({ data, url, key }: any) => {
  const [backgroundColor, setBackgroundColor] = useState('')
  const [contrastColor, setContrastColor] = useState('')
  const [isSvgLogoContrast, setIsSvgLogoContrast] = useState(false)
  const {
    slug,
    modules: { headerBlurb, sectorCategoryRef, companyColor, svgMarkDown, textContrastColors },
  } = data.content.main
  const onMouseOverBackgroundColor = (companyColor: string) => {
    if (companyColor) {
      setContrastColor(textContrastColors && textContrastColors.value)
      setIsSvgLogoContrast(true)
      setBackgroundColor(companyColor)
    }
  }

  const onMouseOverLeave = () => {
    setBackgroundColor('white')
    setContrastColor('#38bd47')
    setIsSvgLogoContrast(false)
  }
  return (
    <PageLink
      activeClass="null"
      to={`/insight/${slug.current}`}
      onMouseLeave={() => onMouseOverLeave('white')}
      key={key}
      onMouseOver={() => onMouseOverBackgroundColor(companyColor)}
      style={{
        background: backgroundColor === companyColor ? companyColor : 'white',
      }}
      className="insight-block padding-box fade-in fade-out text-decoration-none flex column curser "
    >
      <div
        className={`_insight-logo ${isSvgLogoContrast ? '--select-logo' : ''} `}
        dangerouslySetInnerHTML={{ __html: svgMarkDown }}
      />
      <StandardText
        className={`optimo optima-subhead  margin-bottom-4rem margin-top-4rem ${
          isSvgLogoContrast ? 'white' : 'blue'
        }`}
        data={{ text: headerBlurb }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          marginTop: 'auto',
        }}
      >
        <p
          className={` margin-top-4em margin-bottom-1em neue-pro-55-text-links ${
            isSvgLogoContrast ? 'white' : 'blue'
          }`}
        >
          {sectorCategoryRef.title}
        </p>
        <PageLink
          activeClass="null"
          style={{ color: contrastColor }}
          className={`green neue-pro-55-text-links ${
            isSvgLogoContrast ? 'white-text underline-white' : 'green underline-green'
          }`}
          to={`/insight/${slug.current}`}
        >
          {' '}
          View Case Study
        </PageLink>
      </div>
    </PageLink>
  )
}

const ParentDrawerInsightBlockLink = ({
  text,
  color,
  website,
  svgLogo,
  sectorOfCompany,
  toggleDrawer,
  closeDrawer,
  isDrawerOpen,
  _key,
  title,
}: any) => {
  const [backgroundColor, setBackgroundColor] = useState('white')
  const [rollOverColler, setRollOverColler] = useState('')
  const [isSvgLogoContrast, setIsSvgLogoContrast] = useState(false)
  const onMouseOverBackgroundColor = (companyColor: string) => {
    if (companyColor) {
      setBackgroundColor(companyColor)
      setIsSvgLogoContrast(true)
    }
    if (companyColor === 'white') {
      setRollOverColler('#38bd47')
      setIsSvgLogoContrast(false)
    } else {
      setRollOverColler('white')
    }
  }
  return (
    <div
      onClick={e => toggleDrawer(e)}
      onMouseLeave={() => onMouseOverBackgroundColor('white')}
      onMouseOver={() => onMouseOverBackgroundColor(color)}
      style={{ background: backgroundColor === color ? color : 'white' }}
      className="insight-block padding-box fade-in fade-out text-decoration-none flex column  cursor"
    >
      <div
        className={`_insight-logo ${
          isSvgLogoContrast && title !== 'Other Investments' ? '--select-logo' : '__fill-svg '
        }`}
        dangerouslySetInnerHTML={{ __html: svgLogo }}
      />
      <p
        className={`neue-pro-55-text blue margin-bottom-4rem margin-top-4rem ${
          isSvgLogoContrast ? 'white-text' : ''
        }`}
      >
        {' '}
        {text}
      </p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          marginTop: 'auto',
        }}
      >
        <p
          className={`blue margin-top-4em margin-bottom-1em neue-pro-55-text-links ${
            isSvgLogoContrast ? 'white-text' : ''
          }`}
        >
          {sectorOfCompany}
        </p>
        <div className="flex space-between">
          <a
            className={`green neue-pro-55-text-links text-decoration-none ${
              isSvgLogoContrast ? 'underline-white' : 'underline-green'
            } `}
            target="_blank"
            rel="noopener noreferrer"
            href={website}
            style={{ color: rollOverColler }}
          >
            {' '}
            {website ? 'Visit Website' : ''}
          </a>
          {isDrawerOpen === _key ? (
            <HamburgerLine
              onClick={event => closeDrawer(event)}
              className={`__plus-svg ${
                isSvgLogoContrast && title !== 'Other Investments' ? 'fill-white-insight' : ''
              }`}
            />
          ) : (
            <Plus
              onClick={() => toggleDrawer()}
              className={`__plus-svg ${
                isSvgLogoContrast && title !== 'Other Investments' ? 'fill-white-insight' : ''
              }`}
            />
          )}
        </div>
      </div>
    </div>
  )
}
