import React from 'react'

export const TextDisclaimer = ({ data }: any) => {
  const { text } = data
  return (
    <div className="padding-box text-align-center disclaimer-section border-top-border-grey">
      <p className="blue neue-pro-55-text-links nhaas-prop-56-it">{text}</p>
    </div>
  )
}
