import React from 'react'
import { StandardText } from 'src/components/global/standardText'

export interface HeaderBulletinProps {
  data: any
}

export const HeaderBulletin = ({ data }: HeaderBulletinProps) => {
  const { contentColumnOne, contentColumnTwo, contentColumnThree, smallTitle, title, borderBottom } = data
  return (
    <div className="border-top-grey">
      {smallTitle && (
        <p className="neue-pro-55-text-links green text-center margin-top-5rem">{smallTitle}</p>
      )}
      <div className={`header-bulletin`}>
        <div className="__header padding-box">
          <h1 className="neue-pro-light-45-large-nums"> {title}</h1>
        </div>
        <div className="padding-box cjFocus">
          <StandardText
            className="blue neue-pro-light-45-bullet margin-top-059rem"
            data={{ text: contentColumnOne }}
          />
        </div>
        <div className="padding-box cjFocus">
          <StandardText
            className="blue neue-pro-light-45-bullet margin-top-059rem"
            data={{ text: contentColumnTwo }}
          />
        </div>
        {contentColumnThree && (
        <div className="padding-box cjFocus">
          <StandardText
            className="blue neue-pro-light-45-bullet margin-top-059rem"
            data={{ text: contentColumnThree }}
          />
        </div>
        )}
      </div>
    </div>
  )
}