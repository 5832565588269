import React from 'react'
import { StandardText } from 'src/components/global/standardText'
import { Image } from 'src/components/image'

export interface VisualBlockImageDescriptionTextProps {
  data: any
}

export const VisualBlockImageDescriptionText = ({ data }: VisualBlockImageDescriptionTextProps) => {
  const { title, image, text, position, _key, blockContent } = data
  return (
    <div
      key={_key}
      className="two-column-description-image-text visual-block-image-description-text border-bottom-grey"
    >
      <div className="padding-box __info">
        <p className="neue-pro-55-text-links green text-center margin-bottom-em3">{title}</p>
        <StandardText
          className="neue-pro-light-45 text-center blue"
          data={{ text: blockContent }}
        />
      </div>
      <div
        style={{
          gridColumnStart: position && position ? '' : 1,
          borderRight: position && position ? '' : '1px solid #b6bbc1',
        }}
        className="__image-container padding-box"
      >
        {image && <Image width={8000} className="margin-bottom-em3" imageId={image.asset.id} />}
        {text && <p className=" margin-top-em3 neue-pro-55-text-links blue ">{text}</p>}
      </div>
    </div>
  )
}
