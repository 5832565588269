import React from 'react'
import { StandardText } from 'src/components/global/standardText'
import { Image } from 'src/components/image'
import { DownArrowGreen } from 'src/components/svgs'

export interface TextVisualBlockSingleProps {
  data: any
}

export function TextVisualBlockSingle({ data }: TextVisualBlockSingleProps) {
  const { image } = data
  return (
    <div className="text-visual-block-single-text">
      <div className="__info padding-box  text-center">
        <StandardText className="neue-pro-light-45 blue" data={{ text: data.content }} />
        <DownArrowGreen className="--down-svg margin-top " />
      </div>
      <div className="__image-container">
        <Image width={8000} src={image.asset.url} imageId={image.asset.id} />
      </div>
    </div>
  )
}
