import React from 'react'
import cx from 'classnames'

export interface SimpleHeaderProps {
  data: {
    title: string
    borderBottom?: boolean
    blocks: any[]
  }
}

export const SimpleHeader = ({ data }: SimpleHeaderProps) => {
  const { title, blocks, borderBottom } = data

  const hasBottomBorder = typeof borderBottom === 'boolean' && borderBottom

  return (
    <div
      className={cx('page-headline border-top-grey text-center padding-box', {
        'border-bottom-grey': hasBottomBorder,
      })}
    >
      <p className="neue-pro-light-45-headlines blue ">{title}</p>
      <div className="text-center">
        {blocks &&
          blocks.map((item, index) => {
            return (
              <p
                key={`simpleheader-${index + item}`}
                className="blue padding-top-1rem   neue-pro-55-text-links blue"
              >
                {item}
              </p>
            )
          })}
      </div>
    </div>
  )
}
