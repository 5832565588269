import React from 'react'
import { PageLink } from 'src/components/link'
import { RightArrowGreen } from 'src/components/svgs'

export interface TextBlockRelatedMediaBannerProps {
  data: {
    relatedMediaArray: Array<{
      title: string
      previewTextOfContent: string
      relatedMediaTypes: any[]
    }>
  }
}

export const TextBlockRelatedMediaBanner = ({ data }: TextBlockRelatedMediaBannerProps) => {
  const { relatedMediaArray } = data
  return (
    <div className="related-text-block-banner">
      {relatedMediaArray &&
        relatedMediaArray.map(item => {
          const { _type, _key } = item
          switch (_type) {
            case 'externalArticle':
              return (
                <div key={_key} className="__media-block padding-box">
                  <h2 className="blue neue-pro-55-text-links __small-title">{item.title}</h2>
                  <p className=" related-content-links-font-45-light">{item.shortDescription}</p>
                  <a
                    href={item.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`--link-container`}
                  >
                    <RightArrowGreen className="__right-arrow" />
                    <p className="green neue-pro-55-text-links text-decoration-underline green-hover-on-text ">
                      Read More
                    </p>
                  </a>
                </div>
              )
            case 'post':
              return (
                <div key={_key} className="__media-block padding-box">
                  <h2 className="blue neue-pro-55-text-links __small-title">
                    {item.content.main.title}
                  </h2>
                  <p className=" related-content-links-font-45-light">
                    {item.content.main.shortDescription}
                  </p>
                  <InternalType link={item} className="--link-container" />
                </div>
              )

            default:
              return null
          }
        })}
    </div>
  )
}

const InternalType = ({ link, className, _key }: any) => {
  const { _type, content } = link
  let url
  switch (_type) {
    case 'post':
      url = `/article/${content.main.slug.current}`
      break
    case 'page':
      url = `/${content.main.slug.current}`
      break
    default:
      return null
  }
  return (
    <PageLink key={_key} to={url} className={`${className}`}>
      <RightArrowGreen className="__right-arrow" />
      <p className="green neue-pro-55-text-links text-decoration-underline green-hover-on-text ">Read More</p>
    </PageLink>
  )
}
