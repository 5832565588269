import React from 'react'
import { StandardText } from 'src/components/global/standardText'

export interface TextBlockFocusBlurbProps {
  data: {
    array: Array<{
      _key: string
      title: string
      content: any[]
    }>
  }
}

export const TextBlockFocusBlurb = ({ data }: TextBlockFocusBlurbProps) => {
  const { array } = data
  return (
    <div className="text-block-focus-blurb">
      {array &&
        array.map(item => {
          const { title, content } = item
          return (
            <div key={item._key} className="padding-box  __blurb-box ">
              {title && (
                <p className="green __title-blurb neue-pro-55-text-links padding-bottom-5em ">
                  {title}
                </p>
              )}
              {content && (
                <StandardText
                  className="optima-standardtext  white blurb-para"
                  data={{ text: content }}
                />
              )}
            </div>
          )
        })}
    </div>
  )
}
