import React from 'react'
import { StandardText } from 'src/components/global/standardText'

export interface TextBlockFullWhiteProps {
  data: any
}

export const TextBlockFullWhite = ({ data }: TextBlockFullWhiteProps) => {
  const { title, content, text } = data
  return (
    <div className="text-block-one-full-white text-center bordertop-grey-border">
      <div className="padding-box">
        {title && (
          <p className="neue-pro-55-text-links green text-center margin-bottom-em3">{title}</p>
        )}
        {content && (
          <StandardText className=" neue-pro-light-45 text-center blue " data={{ text: content }} />
        )}
        {text && (
          <p className="blue text-center  ma neue-pro-55-text-links max-width-50vw padding-box   ">
            {' '}
            {text}
          </p>
        )}
      </div>
    </div>
  )
}
