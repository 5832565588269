import React from 'react'
import cx from 'classnames'
import { StandardText } from 'src/components/global/standardText'

export interface TextTermsOfuseProps {
  data: any
}

export const TextTermsOfUse = ({ data }: any) => {
  const { content, smallTitle, borderTop } = data
  const hasTopBorder = typeof borderTop === 'boolean' && borderTop

  return (
    <div className={cx({ 'border-top-grey': hasTopBorder })}>
      {smallTitle && (
        <p className="neue-pro-55-text-links green text-center margin-top-5rem">{smallTitle}</p>
      )}
      <div className="padding-box">
        <StandardText className="blue neue-pro-55-text-links" data={{ text: content }} />
      </div>
    </div>
  )
}
