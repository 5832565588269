import React from 'react'
import { PageLink } from 'src/components/link'
import { Image } from 'src/components/image'
import { StandardText } from 'src/components/global/standardText'

const TeamMembersGrid = ({ teamMembers }: any) => (
  <div className="team-list-container-section">
    <div className="__grid  --team-5">
      {teamMembers.map((item, index, array) => {
        return (
          <PageLink
            activeClass="null"
            key={item.key}
            className="text-decoration-none __team-member  fade-in"
            to={`/team/spac/${item.slug.current}`}
          >
            <div className="image size--5x4">
              <picture>
                <img
                  alt={item.image.asset.alt}
                  src={`${item.image.asset.url}?w=400&auto=format&q=85`}
                />
              </picture>
            </div>

            <div className=" __grid2X2 flex column">
              <div className=" flex column  --info ">
                {item.firstName && item.lastName && (
                  <p className="blue neue-pro-55-text margin-bottom-2rem">{`${item.firstName} ${item.lastName}`}</p>
                )}

                {item.spacPositionMarkDown && (
                  <StandardText
                    className="blue neue-pro-55-text blue margin-bottom-2rem "
                    data={{ text: item.spacPositionMarkDown }}
                  />
                )}
                <PageLink
                  activeClass="null"
                  className="neue-pro-55-text green green-underline "
                  to={`/team/spac/${item.slug.current}`}
                >
                  {' '}
                  Read Bio
                </PageLink>
              </div>
            </div>
          </PageLink>
        )
      })}
    </div>
  </div>
)

export default TeamMembersGrid
