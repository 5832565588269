import React from 'react'
import { StandardText } from 'src/components/global/standardText'

export interface TextBlockFullTextBlueProps {
  data: any
}

export const TextBlockOneFullTextBlue = ({ data }: TextBlockFullTextBlueProps) => {
  const { title, content, text } = data
  return (
    <div className="text-block-one-full-blue text-center">
      <div className=" padding-box">
        {title && (
          <p className="neue-pro-55-text-links green text-center margin-bottom-em3">{title}</p>
        )}
        {content && (
          <StandardText
            className="  neue-pro-light-45 text-center white "
            data={{ text: content }}
          />
        )}
        {text && (
          <p className="white text-center  ma neue-pro-55-text-links max-width-50vw padding-box -blue-font-size-small  ">
            {' '}
            {text}
          </p>
        )}
      </div>
    </div>
  )
}
