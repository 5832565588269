import React from 'react'
import { StandardText } from 'src/components/global/standardText'

export interface TextBlockContactGeneralInquiriesProps {
  data: any
}

export const TextBlockContactGeneralInquiries = ({ data }: any) => {
  const { title, contentTwo, content } = data
  return (
    <div className="text-block-general-information-block padding-box">
      <div className="__max-width-30em ma">
        <h1 className="text-center green green optima-headline-pt2 margin-bottom-1em">{title}</h1>
        <StandardText
          className="text-center  neue-pro-55-16px blue margin-bottom-1em "
          data={{ text: content }}
        />
        <StandardText
          className="text-center  blue neue-pro-55-editorial-text"
          data={{ text: contentTwo }}
        />
      </div>
    </div>
  )
}
